import React, { useState, useContext, useRef, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../context/AuthContext";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Tooltip } from 'antd';
import { Menu, Dropdown } from "antd";
import FilterResults from "react-filter-search";
import "./styles.scss";
import moment from "moment";
import "moment/locale/pt-br";
import { useHistory } from "react-router-dom";
import {
  getListCampaigns,
  updatecampaign,
} from "../../services/NexiDigitalAPI";
import { getMenuRoutes } from "../../utils/Utils";

import { CaretDownOutlined, FundOutlined } from "@ant-design/icons";

const Gamification = () => {
  const [name, setName] = useState("");
  const { loginData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const primaryColor = loginData?.primaryColor;
  const secondaryColor = loginData?.secondaryColor;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [changeStatus, setChangeStatus] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [ReportGamification, setPermissionGamification] = useState(false);
  const history = useHistory();
  const isMountedRef = useRef(null);
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Relatórios"
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Gamificação")
            .length
        )
      )
      setPermissionGamification(true)
     
    }
  }
  useEffect(() => {
    isMountedRef.current = true;
    setLoading(true);
    getMenuOption();
    getListCampaigns()
      .then((res) => {
        if (isMountedRef.current) {
          setCampaigns(
            res.sort(
              (a, b) => b.idgamification_campaign - a.idgamification_campaign
            )
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setCampaigns([]);
      });

    return () => (isMountedRef.current = false);
  }, [refresh]);
  const menu = (item) => {
    return (
      <Menu style={{ padding: 10 }}>
        {ReportGamification && 
        <Menu.Item key={1}>
          <div
            onClick={(e) => {
              history.push(
                `/gamification/reports/${item.idgamification_campaign}`
              );
            }}
          >
            Relatórios
          </div>
        </Menu.Item> }
        <Menu.Item key={2}>
          <div
            onClick={(e) =>
              history.push(
                `/gamification/campaigns/${item.idgamification_campaign}`
              )
            }
          >
            Editar
          </div>
        </Menu.Item>
        <Menu.Item key={3}>
          <div
            onClick={(e) => {
              item.status === 1
                ? setActionItem({
                    id: item.idgamification_campaign,
                    status: "Desativar",
                  })
                : setActionItem({
                    id: item.idgamification_campaign,
                    status: "Ativar",
                  });

              setChangeStatus(true);
            }}
          >{`${item.status === 1 ? "Desativar" : "Ativar"}`}</div>
        </Menu.Item>
      </Menu>
    );
  };
  async function actionChangeStatus() {
    setLoading(true);
    const { id, status } = actionItem;
    await updatecampaign(id, { status: status === "Ativar" ? 1 : 0 })
      .then((result) => {
        toast.success(`Campanha ${actionItem.status} com sucesso`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setRefresh(!refresh);
      })
      .catch((err) => {
        alert(err.message);
      });

    setChangeStatus(false);
  }

  function handleChangeName(e) {
    const name = e.target.value;
    if (name && name !== undefined && name != null && name !== "")
      setName(e.target.value);
    else setName("");
  }
  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 600);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return (
    <div className="Cockpit">
      <div className="header-title-wrapper">
        <div className="header-title"> Gamificação </div>
      </div>
      <h5>Campanhas</h5>
      <Modal
        show={changeStatus}
        onHide={() => setChangeStatus(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{actionItem.status} Camapanha?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja {actionItem.status} campanha?
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"secondary"} onClick={() => setChangeStatus(false)}>
            Cancelar
          </Button>
          <Button
            variant={actionItem.status === "Ativar" ? "success" : "danger"}
            onClick={async () => await actionChangeStatus()}
          >
            {actionItem.status}
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <form className="formAddProfile" noValidate autoComplete="off">
          <div className="form-row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Nome</span>
              </div>
              <Col xs={8} sm={6} style={{ paddingLeft: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => {
                    handleChangeName(e);
                  }}
                  placeholder={"nome da campanha"}
                />
              </Col>
              <Col xs={12} sm={4} style={{ paddingLeft: 0 }}>
                <Button
                  onClick={(event) => {
                    history.push(`/gamification/campaigns/${name}`);
                  }}
                  className="blast-button"
                  style={{
                    marginRight: "10px",
                    marginTop: "3px",
                    backgroundColor: primaryColor,
                    color: "#FFF",
                  }}
                >
                  <span>{`${
                    name &&
                    campaigns.filter(
                      (elem) => elem.name.toLowerCase().indexOf(name) > -1
                    ).length
                      ? "Buscar Campanha"
                      : "Cadastrar Campanha"
                  }`}</span>
                </Button>
              </Col>
            </div>
          </div>
        </form>
      </div>
      <hr />
      {loading ? (
        <div className="chart-wrapper">
          <div className="loader loader-big" />
        </div>
      ) : (
        <Table style={{ marginBottom: 0 }}>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>NOME</Th>
              <Th>INÍCIO</Th>
              <Th>FIM</Th>
              <Th>STATUS</Th>
              <Th>AÇÕES</Th>
            </Tr>
          </Thead>

          <Tbody>
            <FilterResults
              value={name >= 3 ? name : ""}
              data={campaigns.filter((elem) => elem.name.indexOf(name) > -1)}
              renderResults={(results) => (
                <>
                  {results.map((item, index) => (
                    <Tr
                      key={item.idgamification_campaign}
                      style={{
                        backgroundColor:
                          index % 2 === 0
                            ? `${secondaryColor.trim()}22`
                            : `${secondaryColor.trim()}14`,
                      }}
                    >
                      <Td>{item.idgamification_campaign}</Td>
                      <Td>{item.name}</Td>
                      <td>{moment(item.startDate).format("DD/MM/YYYY")}</td>
                      <td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
                      <Td className={"td-button"}>
                        <span
                          className={` button badge ${
                            item.status  === 3
                              ? "badge-secondary"
                              : item.status === 1
                              ? "badge-success"
                              : "badge-danger"
                          }`}
                        >
                          {item.status  === 3
                            ? "FINALIZADA"
                            : item.status === 1
                            ? "ATIVA"
                            : "DESATIVADA"}
                        </span>
                      </Td>
                      <Td className={"td-action"}>
                        {item.status === 3 ? (
                          <Tooltip placement="leftTop" title={'Relatórios'}>
                          <FundOutlined
                            style={{ color: primaryColor, fontSize: 20 }}
                            onClick={() =>
                              history.push(
                                `/gamification/reports/${item.idgamification_campaign}`
                              )
                            }
                          /></Tooltip>
                        ) : (
                          <Dropdown
                            overlay={menu(item)}
                            trigger={[isMobile ? "click" : "hover"]}
                            placement="bottomRight"
                            arrow
                          >
                            <CaretDownOutlined
                              style={{ color: primaryColor, fontSize: 20 }}
                            />
                          </Dropdown>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </>
              )}
            />
          </Tbody>
        </Table>
      )}

      {!loading &&
      !campaigns.filter((elem) => elem.name.indexOf(name) > -1).length ? (
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <span>{`Nenhuma campanha ${
            name ? "encontrada" : "cadastrada no momento"
          }`}</span>
        </div>
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Gamification;
