// Basic
import axios from "axios";

// User authentication
export const STORAGE_KEY = "menu-data";
export const isAuthenticated = () => localStorage.getItem(STORAGE_KEY) !== null;
export const API_URL = process.env.REACT_APP_BACKEND_API

export const getToken = () => {
  let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY));
  if (menuData) {
    return menuData.token;
  } else {
    return null;
  }
};

// Init axios object
export let NexiDigitalAPI = axios.create({
  baseURL: API_URL,
  timeout: 1200000,
});

// Insert jwt token from localstorage in authorization header of all requests
NexiDigitalAPI.interceptors.request.use(
  (config) => {
    if (localStorage["token"]) {
      config.headers = { Authorization: "Bearer " + localStorage["token"] };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

NexiDigitalAPI.interceptors.response.use((response) => {
  if (response.data.hasOwnProperty("newToken")) {
    updateToken(response.data.newToken);
  }
  return response;
}, (error) => {
  if(error?.response?.status === 401){
    redirectInInvalidToken()
  }
  return Promise.reject(error)
});

const redirectInInvalidToken = () => {
  localStorage.removeItem(STORAGE_KEY);
  window.location.replace('/');
}

export const getMenuData = () => {
  let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY));
  return menuData;
};

export const setLoginData = (data) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

const updateToken = (newToken) => {
  let menuData = getMenuData();
  menuData.token = newToken;
  setLoginData(menuData);
};

// Autenticação do usuario
export function userLogin(login, password) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/login",
    data: {
      email: `${login}`,
      password: `${password}`,
      idAppType: 1,
    },
  });
}

export function contentGroups(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/groups/`,
  });
}

export function getImportId(id) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/imports`,
  });
}

export function saveSection(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: '/sections',
    data: data
  })
}

export function saveDesignParameter(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: '/design/setparameter',
    data: data
  })
}

export function editMenuItem(data, sectionId) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/sections/editmenuitem/${sectionId}`,
    data: data
  })
}

export function contentTypes() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/types/`,
  });
}

export function updateOfferTerms() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/logincpanel/acceptofferterms/1`,
  });
}

// Edita as informações do aluno
export function setUserAgreement(id, data) {
  return NexiDigitalAPI({
    method: 'PUT',
    url: `/users/agreement/${id} `,
    data: data
  })
}

export function contentItensGroups(id,data='') {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/contentgroup/${id+data}`,
    data: data
  });
}

export const getContentChild = (idJornadinha) => {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/listcontentchild/${idJornadinha}`
  });
}

export function findContentItem(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/content/find/${id}`,
  });
}

export function getTags() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/tag/list/`,
  });
}

export function updateAppDetails(designData) {
  return NexiDigitalAPI({
    method: "POST",
    url: '/app/updateappdesign',
    data: designData
  })
}

export function setTags(tag) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/tag/insert/`,
    data: {
      tag: `${tag}`,
    },
  });
}

export function removeTag(id) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/tag/remove/${id}`,
  });
}

export function getPermissions(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/permission/${id}`,
  });
}

export function addPermission(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/permission`,
    data: data,
  });
}

export function deletePermission(id) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/permission/${id}`,
  });
}

export function getCompany(appId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/companies/${appId}`,
  });
}

export function getCompanyByChannel(channelId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/companies/${channelId}`,
  });
}

export function addCompany(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: "/companies",
    data: data,
  });
}

export function updateCompany(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/companies",
    data: data,
  });
}

export function activateCompany(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/companies/activate",
    data: data,
  });
}

export function deactivateCompany(data) {
  return NexiDigitalAPI({
    method: "POST",
    url: "/companies/deactivate",
    data: data,
  });
}

export function deleteCompany(idCompany) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/companies/${idCompany}`,
  });
}

export function getChannel() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/channel`,
  });
}

export function getChannelCampaign() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/channel/listchannelcampaign`,
  });
}

export function getApp() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/app`,
  });
}

export const logout = () => {
  localStorage.removeItem(STORAGE_KEY);
};

export function getSectionTypeList() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/sections/listsectiontype`,
  });
};

export function getSectionDesignList(sectionId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/sections/listsectiondesign/${sectionId}`,
  });
};

export function getMenuList() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/sections/listmenu`,
  });
};

export function getMenuDesign(sectionId) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/sections/listmenudesign/${sectionId}`,
  });
};

export function uploadImageApi(data,progressItens='') {

  data.append("token", getToken());
  return axios.post(`${process.env.REACT_APP_UPLOAD_FILE}`, data, {

    onUploadProgress: (event) => {
      let progress: number = Math.round(
        (event.loaded * 100) / event.total
      );

      if( progressItens ){
        //console.log(progressItens.uploadItem);
        let uploadItem = {'fileName': data.get('fileName'),'name': (data.get('nameOriginal')!='undefine'?data.get('nameOriginal'):''), 'progress':progress};
        progressItens.setUploadItem(uploadItem);
      }

    },

    headers: {
      "Content-Type": `multipart/form-data`,
    },

  });

  // return NexiDigitalAPI({
  //   method: "POST",
  //   url: `/upload`,
  //   data: data,
  //   headers: {
  //     accept: "application/json",
  //     "Accept-Language": "en-US,en;q=0.8",
  //     "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
  //   },
  // });
}


export function uploadWebSite(data,progressItens='',set) {

  var fileName = (data.get('fileName')!='undefine'?data.get('fileName'):'');
  if(fileName){
    data.delete('fileName');
  }

  var nomeOriginal = (data.get('nameOriginal')!='undefine'?data.get('nameOriginal'):'');
  if(nomeOriginal){
    data.delete('nameOriginal');
  }

  return axios.post(`${process.env.REACT_APP_UPLOAD_WEBSITE}`, data, {

    onUploadProgress: (event) => {
      let progress: number = Math.round(
        (event.loaded * 100) / event.total
      );

      if( progressItens ){
        let uploadItem = {'fileName': 'uploadWebSite','name': nomeOriginal, 'progress':progress};
        progressItens.setUploadItem(uploadItem);
      }

    },

    headers: {
      "Content-Type": `multipart/form-data`,
      "Authorization": `Bearer ${getToken()}`
    },

  });

}

export function uploadCsvApi(data) {
  data.append("token", getToken());
  return axios.post(`${process.env.REACT_APP_UPLOAD_USERIMPORT}`, data, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
}

export function checkImportStatus(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/imports/${id}/status`,
  });
}

export function addNewContent(data) {
  const {
    text,
    description,
    idContentType,
    idContentGroup,
    referenceLink,
    contentLocation
  } = data;

  return NexiDigitalAPI({
    method: "POST",
    url: `/content/`,
    data: {
      text,
      description,
      idContentType: `${idContentType}`,
      idContentGroup: `${idContentGroup}`,
      referenceLink: `${referenceLink}`,
      contentLocation: `${contentLocation}`,
      active: idContentGroup == 114 || idContentGroup == 138 ? 1 : 0 // 114 = jornadinha 138 = impact
    },
  });
}

export function addNewChildContent(data) {
  const {
    text,
    description,
    idContentType,
    idContentGroup,
    referenceLink,
    contentLocation,
    parent
  } = data;

  let newData = {...data, active:0};

  return NexiDigitalAPI({
    method: "POST",
    url: `/content/`,
    data: newData
    /*{
      text,
      description,
      idContentType: `${idContentType}`,
      idContentGroup: `${idContentGroup}`,
      referenceLink: `${referenceLink}`,
      contentLocation: `${contentLocation}`,
      active: 0,
      parent: `${parent}`
    },*/
  });
}

export function orderJourneyPart(newOrder) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/content/orderContent`,
    data: {
      contentList: newOrder // [1, 2, 3] use idContentItem
    }
  });
}

// Seleciona o appType de acordo com o endereco
export function appDetails(bucket) {
  return axios.post(
    `${API_URL}/app/details/`,
    { "bucket": bucket },
  )
}

export function updateContent(data) {
  const { idContentItem } = data;
  // remove o content id do objeto
  delete data.idContentItem;

  return NexiDigitalAPI({
    method: "PUT",
    url: `/content/${idContentItem}`,
    data: data,
  });
}

export function updateContentHome(data) {
  const { idContentItem } = data;

  return NexiDigitalAPI({
    method: "PUT",
    url: `/content/${idContentItem}`,
    data: data,
  });
}

export function zoom_auth(code) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/meeting/zoom/auth/code=${code}`,
    data: {
      code: code,
    },
  });
}

export function zoom_edit(meeting) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/meeting/zoom/editzoommeeting`,
    data: {
      token: localStorage.getItem("zoom_token"),
      meeting: meeting,
    },
  });
}

export function zoom_add(meeting) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/meeting/zoom/setzoommeeting`,
    data: {
      token: localStorage.getItem("zoom_token"),
      meeting: meeting,
    },
  });
}

// Groups
export function getGroups() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/group/list`,
  });
}

export function createGroup(group) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/group/addgroup/`,
    data: {
      title: `${group}`,
    },
  });
}

export function updateGroup(id, group) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/group/${id}`,
    data: {
      title: `${group}`,
    },
  });
}

export function removeGroup(id) {
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/group/${id}`,
  });
}

export function getGroupUsers(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/group/groupusers/${id}`,
  });
}

export function linkUserToGroup(idGroups, idUser) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/group/linkuser`,
    data: {
      idGroups,
      idUser,
    },
  });
}

export function unlinkUserFromGroup(idGroups, idUser) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/group/unlinkuser`,
    data: {
      idGroups,
      idUser,
    },
  });
}

export function getUsers() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/users`,
  });
}

export function getUsersApi() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/users`,
  }).then( result => {
      if (result.data.success === true) {
        return (result.data.userList !== undefined) ? result.data.userList : [];
      }
      return []
    }
  ).catch((err) => {
    return []
    throw err;
  });
}

export function getUser(id) {
  return NexiDigitalAPI({
    method: "GET",
    url: `/users/${id}`,
  });
}

export function editUser(id, data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: `/users/${id}`,
    data: data,
  });
}

export function topRanking(filter){
  return NexiDigitalAPI({
    method: "POST",
    url: '/reports/listtopranking',
    data: filter,
  })
  .then((response) => {
    if (response.data.success === true && response.data.data !== undefined) {
      return response.data.data;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}
export function postUserVisits(startDate, endDate, idUser) {
  return NexiDigitalAPI({
    method: "POST",
    url: `/reports/uservisits`,
    data: {
      startDate: startDate,
      endDate: endDate,
      idUser: idUser
    }
  }
  )
}

export function lastRanking(filter){
  return NexiDigitalAPI({
    method: "POST",
    url: '/reports/listlastranking',
    data: filter,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data.data;
    }
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function listAllRooms() {
  return NexiDigitalAPI({
    method: "GET",
    url: `/chat/listallrooms`,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data.chatrooms;
    }
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function reportChatContent(filter){
  return NexiDigitalAPI({
    method: "POST",
    url: '/reports/listchatcontent',
    data: filter,
  })
  .then((response) => {
    if (response.data.success === true) {
      return (response.data.data !== undefined) ? response.data.data : [];
    }
  })
  .catch((err) => {
    throw err;
  });
}

export function reportGetUserUpload(){
  return NexiDigitalAPI.get(`/userupload/listsectionorigin`)
    .then((response) => {
      if (response.data.success === true) {
        return response.data.result;
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function reportUserNoUpload(filter){
  return NexiDigitalAPI({
    method: "POST",
    url: '/reports/listusernoupload',
    data: filter,
  })
  .then((response) => {
    if (response.data.success === true) {
      return (response.data.content !== undefined) ? response.data.content : [];
    }
  })
  .catch((err) => {
    throw err;
  });
}

export function reportUserUpload(filter){
  return NexiDigitalAPI({
    method: "POST",
    url: '/reports/listuserupload',
    data: filter,
  })
  .then((response) => {
    if (response.data.success === true) {
      return (response.data.content !== undefined) ? response.data.content : [];
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function getSectionsList(idAppType){
  return NexiDigitalAPI.get(`/sections/${idAppType}`)
    .then((response) => {
      if (response.data.success === true) {
        return response.data.sections;
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function deleteSection(idAppType){
  return NexiDigitalAPI({
    method: "DELETE",
    url: `/sections/${idAppType}`,
  });
}

export function getSectionsStructureList(idAppType){
  return NexiDigitalAPI.get(`/sections/getmenustructure/${idAppType}`)
    .then((response) => {
      if (response.data.success === true) {
        return response.data;
      } else {
        return []
      }
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getContentList(sectionSelected){
  return NexiDigitalAPI.get(`/content/list/${sectionSelected}`)
    .then((response) => {
      if (response.data.success === true && response.data.content) {
        return response.data.content;
      }

      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getGroupList(){
  return NexiDigitalAPI.get(`/group/list`)
    .then((response) => {
        if (response.data.success === true) {
          return response.data.groups
        }

        return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function reportStatisticsAccess( values ){
  return NexiDigitalAPI.post(`/reports/statisticsaccess`, values)
    .then((response) => {
        if (response.data.success === true) {
          return response.data;
        }

        return false;
    })
    .catch((err) => {
      return false;
      throw err;
  });
}

export function getReportSections( values ){
  return NexiDigitalAPI.post(`/reports/sessions`, values)
    .then((response) => {
      if (response.data.success === true) {
        return response.data.data
      }

      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getReportUserActivity( values ){
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/uservisits",
    data: values,
  }).then((response) => {
      if (response.data.success === true) {
        return response.data.data
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getReportContentengagement(values){
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/contentengagement",
    data: values,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data.data;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function getReportNonAccess(values){
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listcontentnonccess",
    data: values,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data.data;
    }

    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}
export function getReportListtopcontentaccess(values){
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listtopcontentaccess",
    data: values,
  })
  .then((response) => {
    if (response.data.success === true && response.data.data !== undefined) {
      return response.data.data;
    }

    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}


export function getReportContentCommented(values){
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listcontentcommented",
    data: values,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data.content;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function getReportListlestcontentaccess(values){
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listlastcontentaccess",
    data: values,
  })
  .then((response) => {
    if (response.data.success === true && response.data.data !== undefined) {
      return response.data.data;
    }
    return [];
  })
  .catch((err) => {
    return [];
  });
}

export function getOfferList(searchText){
  return NexiDigitalAPI({
    method: 'POST',
    url: '/store/offers',
    data: { searchText: searchText}
  }).then((response) => {
    if (response.data.success === true) {
      return response.data.offers;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function getAdminOfferList(searchText){
  return NexiDigitalAPI({
    method: 'POST',
    url: '/store/listoffers',
    data: { searchText: searchText}
  }).then((response) => {
    if (response.data.success === true) {
      return response.data.offers;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function getProductList(){
  return NexiDigitalAPI({
    method: 'GET',
    url: '/store/listproducts'
  }).then((response) => {
    if (response.data.success === true) {
      return response.data.listProducts;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function postCreateProduct(info){
  return NexiDigitalAPI({
    method: 'POST',
    url: '/store/createproduct',
    data: info
  }).then((response) => {
    if (response.data.success === true) {
      return true;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function postEditProduct(info,id){
  return NexiDigitalAPI({
    method: 'POST',
    url: `/store/editproduct/${id}`,
    data: info
  }).then((response) => {
    if (response.data.success === true) {
      return true;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function postCreateOffer(info){
  return NexiDigitalAPI({
    method: 'POST',
    url: '/store/createoffer',
    data: info
  }).then((response) => {
    if (response.data.success === true) {
      return true;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function postEditOffer(info,id){
  return NexiDigitalAPI({
    method: 'POST',
    url: `/store/editoffer/${id}`,
    data: info
  }).then((response) => {
    if (response.data.success === true) {
      return true;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function listPurchase(data){
  return NexiDigitalAPI({
    method: 'POST',
    url: `/store/listpurchase`,
    data: data
  }).then((response) => {
    if (response.data.success === true) {
      return response.data.purchase;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function listPurchaseDetail(id){
  return NexiDigitalAPI({
    method: 'GET',
    url: `/store/listpurchasedetail/${id}`
  }).then((response) => {
    if (response.data.success === true) {
      return response.data.products;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function listPurchaseDailyReport(data){
  return NexiDigitalAPI({
    method: 'POST',
    url: `store/listpurchasesummarydaily`,
    data: data
  }).then((response) => {
    if (response.data.success === true) {
      return response.data.dates;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function listPurchaseProductReport(data){
  return NexiDigitalAPI({
    method: 'POST',
    url: `/store/listpurchaseproduct`,
    data: data
  }).then((response) => {
    if (response.data.success === true) {
      return response.data.purchases;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export function cancelpurchase(id){
  return NexiDigitalAPI({
    method: 'GET',
    url: `/store/cancelpurchase/${id}`,
  }).then((response) => {
    if (response.data.success === true) {
      return true
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}



export async function GetUtilizationPerWeekDay(values) {
  try {
    const response = await NexiDigitalAPI.post(`/reports/getutilizationperweekday`, values);
    if (response.data.success === true) {
      return response.data.period;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export async function GetUtilizationPerWeekDays(values) {
  try {
    const response = await NexiDigitalAPI.post(`/reports/getutilizationperday`, values);
    if (response.data.success === true) {
      return response.data.period;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export async function GetContentCountEngagement(values) {
  try {
    const response = await NexiDigitalAPI.get(`/content/contentcountengagement`, values);
    if (response.data.success === true) {
      return response.data;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export function addProfileApi(name){
  const data = {
    name: name,
  }
  return NexiDigitalAPI({
    method: "PUT",
    url: "/permission/insertprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function updateProfileApi(idProfile, name){
  const data = {
    name: name,
  }
  return NexiDigitalAPI({
    method: "POST",
    url: "/permission/updateprofile/" + idProfile,
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getProfiles(values){
  return NexiDigitalAPI({
    method: "GET",
    url: "/permission/listProfile",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listaPerfil;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function deleteProfile(idProfile){
  return NexiDigitalAPI({
    method: "DELETE",
    url: "/permission/deleteprofile/" + idProfile,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getPermissionResources(values){
  return NexiDigitalAPI({
    method: "GET",
    url: "/permission/listResource",
    data: values,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listaRecursos;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function getProfileResourcesApi(id){
  const data = {
    idProfile: id
  }
  return NexiDigitalAPI({
    method: "POST",
    url: "/permission/listResourceProfile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listaPerfil;
      }
      return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function addProfileResourcesApi(idProfile, idResource){
  const data = {
    idProfile: idProfile,
    idResource: idResource
  }
  return NexiDigitalAPI({
    method: "PUT",
    url: "/permission/insertresourceprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function removeProfileResourcesApi(idProfile, idResource){
  const data = {
    idProfile: idProfile,
    idResource: idResource
  }
  return NexiDigitalAPI({
    method: "DELETE",
    url: "/permission/deleteresourceprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function addUserIntoProfile(idProfile, idUser){
  const data = {
    idUser: idUser,
    idProfile: idProfile
  }
  return NexiDigitalAPI({
    method: "PUT",
    url: "/permission/insertuserprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function getProfileUsers(idProfile) {
  return NexiDigitalAPI({
    method: "GET",
    url: "/permission/listuserprofile/" + idProfile,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.listUserProfile;
      }
      return false;
    })
    .catch((err) => {
      return false;
      throw err;
    });
}

export function deleteUserOfProfile(idProfile, idUser){
  const data = {
    idUser: idUser,
    idProfile: idProfile
  }
  return NexiDigitalAPI({
    method: "DELETE",
    url: "/permission/deleteuserprofile",
    data: data,
  })
    .then((response) => {
      if (response.data.success === true) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}
export function getContentWithAcessInteration(values){
  return NexiDigitalAPI({
    method: "POST",
    url: "/reports/listlastcontentaccess",
    data: values,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data.data;
    }
    return false;
  })
  .catch((err) => {
    return false;
  });
}

export function getListCampaigns(){
  return NexiDigitalAPI({
    method: "GET",
    url: "/campaign/listcampaigns",
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.campaigns
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}

export async function getListCampaignsDetail(id) {
  return await NexiDigitalAPI({
    method: "GET",
    url: `/campaign/listcampaigndetail/${id}`,
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function getCampaignsEvents() {
  return await  NexiDigitalAPI({
    method: "GET",
    url: "campaign/listevents/",
  })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.event;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export function insertCampaigns(data) {
  return NexiDigitalAPI({
    method: "PUT",
    url: "campaign/insertcampaign/",
    data:data
  })
    .then((response) => {
      if (response.data.success === true) {
          return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function updatecampaign(id, data) {
  return await NexiDigitalAPI({
    method: "POST",
    url: `campaign/updatecampaign/${id}`,
    data:data
  })
    .then((response) => {
      if (response.data.success === true) {
          return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function listcampaignpointssummary(id) {
  return await NexiDigitalAPI({
    method: "GET",
    url: `campaign/listcampaignpointssummary/${id}`,
  })
    .then((response) => {
      if (response.data.success === true) {
          return response.data.campaignpoints;
      }
      return false
    })
    .catch((err) => {
      throw err;
    });
}
export async function listcampaignuserpoints(data) {
  return await NexiDigitalAPI({
    method: "POST",
    url: `campaign/listcampaignuserpoints/`,
    data:data
  })
    .then((response) => {
      if (response.data.success === true) {
          return response.data.campaignuserpoints;
      }
      return false

    })
    .catch((err) => {
      throw err;
    });
}

export async function getAppColors() {
  return await NexiDigitalAPI({
    method: "GET",
    url: `companies/listlookandfeel/`
  })
    .then((response) => {
      if (response.data.success === true) {
          return response.data.lookandFeel;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function updateAppColors(data) {
  return await NexiDigitalAPI({
    method: "POST",
    url: `companies/lookandfeel/`,
    data:data
  })
    .then((response) => {
      if (response.data.success === true) {
          return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function getTokenToApplyQrcode() {
  return await NexiDigitalAPI({
    method: "GET",
    url: `companies/gettoken/`
  })
    .then((response) => {
      if (response.data.success === true) {
          return response.data;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function checkQrCodeEnable(token) {
  try {
    return axios.get(
        `${API_URL}/companies/availablelicences`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          "Content-Type": "application/json",
        }
      )
      .then(({ data }) => {
        return Boolean(data.availableLicences > 0)
      })
      .catch((e) => {
        console.log(e)
      });
  } catch (e) {
    return 'false';
  }

}

export async function listUserEngagement(values) {
  return await NexiDigitalAPI({
    method: "post",
    url: `/reports/userconsumption`,
    data:values
  }).then((response) => {
    if (response.data.success === true && response.data.data !== undefined) {
      return response.data.data;
    }
    return [];
  })
  .catch((err) => {
    return [];
    throw err;
  });
}

export async function listContentTypeProgress() {
  return await NexiDigitalAPI({
    method: "GET",
    url: `/content/listcontenttypeprogress`
  })
    .then((response) => {
      if (response.data.success === true) {
          return response.data.content;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}

export async function listUsersAccess(values) {
  return await NexiDigitalAPI({
    method: "POST",
    url: `/reports/contentengagementpercontent`,
    data: values
  })
    .then((response) => {
      if (response.data.success === true) {
          return response.data.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}


export async function listTagsReport() {
  return await NexiDigitalAPI({
    method: "POST",
    url: `/content/listcontenttag`,
  })
    .then((response) => {
      console.log('response', response) 
      if (response.data.success === true) {
          return response.data.contenttaglist;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}


export async function listTopContentItemProgress() {
  return await NexiDigitalAPI({
    method: "GET",
    url: `/content/listtopcontentitemprogress`
  })
    .then((response) => {
      if (response.data.success === true) {
          return response.data.content;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });
}


/*
*
*funcoes agenda
*
*/
export function getSchedules(){
  return NexiDigitalAPI({
    method: "GET",
    url: "/schedule/listschedules",
    headers: {
      "Authorization": `Bearer ${getToken()}`
    },
  }).then((response) => {
     return response;
    })
    .catch((err) => {
      throw err;
    });
}

export function insertSchedules(values){
  return NexiDigitalAPI({
    method: "PUT",
    url: "/schedule/insertschedule",
    data: values,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function updateSchedules(values){
  return NexiDigitalAPI({
    method: "POST",
    url: "/schedule/updateschedule/"+values.id,
    data: values,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function deleteSchedules(id){
  return NexiDigitalAPI({
    method: "DELETE",
    url: "/schedule/"+id,
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function getSchedulesTypes(id){
  return NexiDigitalAPI({
    method: "GET",
    url: "/schedule/listschedulestype",
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data.schedulesType;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}
/* termina funcoes agenda*/

export function sendEmailBoasVindasApi(email){
  return NexiDigitalAPI({
    method: "post",
    url: "/login/sendwelcomeemail",
    data: { email: email },
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function contentOrder(list) {
  const data = {
    contentList: list
  }
  return NexiDigitalAPI({
    method: "Post",
    url: `/content/orderContent`,
    data: data
  });
}


export default NexiDigitalAPI;
