import React, { useState, useEffect } from 'react'
import Loading from '../../components/Loading';
import { getSectionsList, deleteSection } from '../../services/NexiDigitalAPI'
import { useHistory } from "react-router-dom";
import { TextField } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { BsTrash } from 'react-icons/bs';
import CircularProgress from "@material-ui/core/CircularProgress";

const SectionDesignList = () => {
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [currentDeleteSection, setCurrentDeleteSection] = useState(0);
  const [sectionList, setSectionList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const appDetails = JSON.parse(localStorage.getItem('appDetails')) || {}

  const history = useHistory()

  const _getSectionsList = async () => {
    setLoading(true);
    try {
      const result = await getSectionsList(appDetails?.idAppType);
      setSectionList(result);
      setFilteredList(result);
    } catch (err) {
      console.log("Erro na chamada de API.", err);
    } finally {
      setLoading(false);  
    }
  };

  const _deleteSection = async (idSection) => {
    setCurrentDeleteSection(idSection)
    setDeleteLoading(true)
    setDeleteError(false)
    deleteSection(idSection).then(res => {
      if(res.data.success == true){
        let filterDeleted = [...filteredList]
        const indexDeleted = filteredList.findIndex(item => item.idSection === idSection)
        filterDeleted.splice(indexDeleted, 1)
        setFilteredList(filterDeleted)
      }else{
        setDeleteError(true)
      }
  }).catch(err => {
      console.log('error ', err)
      setDeleteError(true)
  }).finally(() => {
      setDeleteLoading(false)
  })
  }

  const normalize = (text) => {
    return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  }

  const filterByName = (filterValue) => {
    setFilteredList([...sectionList.filter(item => normalize(item.name).includes(normalize(filterValue)))])
  }

  useEffect(() => {
    _getSectionsList()
  }, [])

  return (
      <div className="section-design-tree-container">
        {
          loading ? (
            <div style={{ height: 300 }}>
              <Loading center> Carregando lista de seções... </Loading>
            </div>
          ) : (
            <div>
              <div style={{ marginBottom: 10 }}>
                <TextField
                    onChange={event => filterByName(event.target.value)}
                    variant='outlined'
                    placeholder='Procurar por nome'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                />
              </div>
              <div className="section-design-header">
                <div>Nome</div>
                <div>ID Seção</div>
                <div>Tipo</div>
                <div>ID Tipo de Seção</div>
                <div>Ações</div>
              </div>
              {
                filteredList?.map((item, index) => (
                    <div 
                      className="section-design-item-container" 
                      key={index}
                      onClick={(event) => {
                        // dont trigger parent div onClick of  clicked on actions button
                        if(
                            event.target.className === 'section-design-item-action-item' || 
                            event.target.className === 'section-design-item-action' || 
                            event.target.tagName === 'svg'
                          ) return;
                        history.push(`/section-design/${item.idSection}`)
                      }}
                    >
                      <div className="section-design-item-value">
                        {item.name}
                      </div>
                      <div className="section-design-item-value">
                        {item.idSection}
                      </div>
                      <div className="section-design-item-value">
                        {item.sectionTypeName}
                      </div>
                      <div className="section-design-item-value">
                        {item.idSectionType}
                      </div>
                      <div className="section-design-item-action">
                        <div 
                          onClick={() => _deleteSection(item.idSection)} style={{ color: 'red', cursor: 'pointer' }}
                          className="section-design-item-action-item"
                        >
                          {
                            currentDeleteSection === item.idSection && deleteLoading ?
                              <CircularProgress size={18} /> :
                              <BsTrash fontSize={18} /> 
                          }
                        </div>
                    </div>
                  </div>
                ))
              }
              {
                deleteError &&  
                  <div style={{color: 'red', padding: 10}}>
                    Erro ao deletar seção.
                  </div>
              }
            </div>
          )
        }
      </div>
  )
}

export default SectionDesignList