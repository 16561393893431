import React from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from './styles.module.scss'

const Table = ({ dados, loading }) => {
    const columns = [
      { name: "Tag", selector: "tag", sortable: true },
      { name: "Conteúdo", selector: "content", sortable: true },
      { name: "Tipo", selector: "type", sortable: true },
      { name: "Seção", selector: "section", sortable: true }  
      ];

    const customTableStyles = {
        rows: {
          style: {
            minHeight: '60px', // override the row height
          }
        },
        headCells: {
          style: {
            paddingLeft: '30px', // override the cell padding for head cells
          },
        },
        cells: {
          style: {
            paddingLeft: '30px', // override the cell padding for data cells
          },
        },
        header: {
          style: {
            fontSize: '22px',
            minHeight: '56px',
            paddingLeft: '28px',
            paddingRight: '8px',
            paddingTop: '30px',
            paddingBottom: '15px',
          },
        },
      };
    
    const tableData = {
        columns: columns,
        data: dados
    }

    const loadingComponent = () => {
        return (
          <div className="tela-login-loading">
            <div className="loader-container" style={{ margin: '300px 0' }}>
              <div
                style={{
                  position: 'initial',
                  margin: 'initial'
                }}
                className="loader loader-center loader-big">
              </div>
              <span style={{
                marginTop: 10,
                fontSize: 20
              }}>
                Carregando dados...
              </span>
            </div>
          </div>
        )
      }

    return (
        <DataTableExtensions filter={false} print={false} {...tableData} exportHeaders={true}>
          <DataTable
            columns={columns}
            data={dados}
            pagination
            className={styles.dataTableFormat}
            customStyles={customTableStyles}
            progressPending={ loading }
            progressComponent={loadingComponent()}
            noHeader={true}
            noDataComponent="Não foi encontrado nenhum dado"
          />
        </DataTableExtensions>
    )

}

export default Table;